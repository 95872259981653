.search-profile-main-cal{
  text-align: center;
}
.search-profile-main-cal thead tr:first-child{
  background: #f1f1f1;
  font-size: 0.8rem;
  line-height: 1.6;
  color: #8c8583;
}
.search-profile-main-cal .dow{
  font-size: 0.8rem;
  line-height: 2.8;
  border-bottom: 1px solid #e4ebee;
}
.search-profile-main-cal .rdtSwitch,
.search-profile-main-cal .rdtNext,
.search-profile-main-cal .rdtPrev {
  font-weight: normal;
  color: #b6aba8;
  user-select: none;
}
.search-profile-main-cal .rdtNext,
.search-profile-main-cal .rdtPrev {
  cursor: pointer;
}
.search-profile-main-cal .rdtNext span,
.search-profile-main-cal .rdtPrev span{
  color: #f4b29a;
  font-size: 1.3rem;
}
.search-profile-main-cal .rdtDay:not(.calendar-day-not-this-month),
.search-profile-main-cal .rdtMonth,
.search-profile-main-cal .rdtYear{
  font-size: 0.8rem;
  color: #b6aba8;
}
.calendar-rightbar .search-profile-main-cal .calendar-day-not-this-month{
  height: 0!important;
  padding: 0!important;
}
.search-profile-main-cal .rdtDay,
.search-profile-main-cal .rdtMonth,
.search-profile-main-cal .rdtYear{
  height: 36px!important;
}
.search-profile-main-cal.absolute-cal .rdtOpen{
  position: absolute;
  top: 2.9rem;
  right: 1.4rem;
  width: 15rem;
  background: #fff;
  border-top: 1px solid #e4ebee;
  border-left: 1px solid #e4ebee;
  border-right: 1px solid #e4ebee;
  cursor: pointer;
  z-index: 999999999999999 !important;
}
.become-sitter-modal .search-profile-main-cal.absolute-cal .rdtOpen{
  bottom: 2.1rem;
  right: 0;
}
.profile-content .search-profile-main-cal.absolute-cal .rdtOpen{
  bottom: 2.3rem;
}
.search-profile-main-cal table{
  width: 100%;
}