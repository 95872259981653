.modal-back-layer{
  position: fixed;
  z-index: 999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: rgba(0,0,0,0.4);
  display: flex;
  justify-content: center;
  padding-top: 62px;
}
.modal-wrapper{
  background: #fff;
  position: relative;
  white-space: pre-line;
  margin: auto;
  overflow: auto;
  width: max-content;
  max-width: 800px;
    padding: 15px !important;
    overflow: auto;
    max-height: 100%;
}
.modal-wrapper .pop-style{
    margin: 0;
}
.modal-wrapper .close-button{
  position: absolute;
  top: 0;
  right: 0;
  display: inline-block;
  height: 24px;
  width: 24px;
  text-align: center;
  line-height: 22px;
  cursor: pointer;
    background: #fff;
}
