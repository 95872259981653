/* The Modal (background) */
.modal-custom {
    position: fixed; /* Stay in place */
    z-index: 999; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: hidden; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

.modal-father{
    height: 100%;
	width: 100%;
	overflow: auto;
}

.modal-custom-body{
    background: #fff;
    position: relative;
	min-height: 120px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.btn-file-upload {
	margin-top: 15px;
	margin-bottom: 5px;
}

.modal-custom-body .close{
	position: absolute;
	top: 0;
	right: 0;
    background: #fff;
    opacity: 1;
}
.close-modal-button{
    position: absolute;
    top: -6px;
    right: -7px;
    display: inline-block;
    height: 24px;
    width: 24px;
    background: #fff;
    text-align: center;
    border-radius: 50%;
    line-height: 22px;
    border: 1px solid #f4b29a;
    cursor: pointer;
}
.modal-button {
	height: 35px;
	text-align: center;
	border-radius: .3rem;
	font-size: 1rem;
	cursor: pointer;
	position: relative;
	width: fit-content;
	padding: 0 2rem;
	background: #fff;
}

.info{
	border: 1px solid #28d0df;
	box-shadow: 0 2px 10px 2px rgba(0,0,0,.065);
}

.info:hover{
	-webkit-box-shadow: 0 2px 12px 2px rgba(45,207,222,0.14);
	-moz-box-shadow: 0 2px 12px 2px rgba(45,207,222,0.14);
	box-shadow: 0 2px 12px 2px rgba(45,207,222,0.14);
}

.success{
	border: 1px solid #63ae84;
	box-shadow: 0 2px 10px 2px rgba(0,0,0,.065);
}

.success:hover{
	-webkit-box-shadow: 0 2px 12px 2px rgba(99, 174, 132, 0.14);
	-moz-box-shadow: 0 2px 12px 2px rgba(99, 174, 132, 0.14);
	box-shadow: 0 2px 12px 2px rgba(99, 174, 132, 0.14);
}

.error{
	border: 1px solid #de5038;
	box-shadow: 0 2px 10px 2px rgba(0,0,0,.065);
}

.error:hover{
	-webkit-box-shadow: 0 2px 12px 2px rgba(222,80,56,0.14);
	-moz-box-shadow: 0 2px 12px 2px rgba(222,80,56,0.14);
	box-shadow: 0 2px 12px 2px rgba(222,80,56,0.14);
}

.danger{
	border: 1px solid #ffa17b;
	box-shadow: 0 2px 10px 2px rgba(0,0,0,.065);
}

.danger:hover{
	-webkit-box-shadow: 0 2px 12px 2px rgba(255, 161, 123, 0.14);
	-moz-box-shadow: 0 2px 12px 2px rgba(255, 161, 123, 0.14);
	box-shadow: 0 2px 12px 2px rgba(255, 161, 123, 0.14);
}
