.marker-holder{
	position: absolute;
	width: 60px;
	height: 60px;
	cursor: pointer;
	background: no-repeat center;
	background-size: cover;
}
.marker-title{
	position: relative;
	color: #fff;
	font-weight: 600;
	font-size: 0.9em;
	text-align: center;
	-moz-user-select: none;
	-webkit-user-select: none;
	-ms-user-select: none;
	user-select: none;
	white-space: nowrap;
  margin: 5px 0;
}
