/***** @import '~bootstrap/dist/css/bootstrap.min.css'; ******/
/*@import url('https://fonts.googleapis.com/css?family=Lato:100,100i,300,300i,400,400i,700,700i,900,900i');*/
@import url('https://fonts.googleapis.com/css?family=Playfair+Display:400,400i,700,700i,900,900i');
/*@import url('https://fonts.googleapis.com/css?family=Montserrat');
@import url('https://fonts.googleapis.com/css?family=Noto+Sans:400,400i,700,700i|PT+Sans:400,400i,700,700i'); */
@import url('https://fonts.googleapis.com/css?family=Lato:100,100i,300,300i,400,400i,700,700i,900,900i');
@import '~bootstrap/dist/css/bootstrap.css';
@import '~bootstrap/dist/css/bootstrap-grid.css';
@import '~bootstrap/dist/css/bootstrap-reboot.css';
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
@import "~react-big-calendar/lib/css/react-big-calendar.css";
@import "~card-react/lib/card.css";
@import "~react-notifications/lib/notifications.css";
@import './icomoon.css';
@import 'style.css';
@import 'media.css';


.hide-arrow-calendar.no-arrow .rdtPrev span, .hide-arrow-calendar.no-arrow .rdtNext span {
    display: none;
}

.hide-arrow-calendar .rdtSwitch {
    text-align: center;
}

.hide-arrow-calendar .rdtPrev, .hide-arrow-calendar .rdtNext, .hide-arrow-calendar .rdtSwitch {
    color: #ffffff;
    background-color: #19108b;
}

.Select--multi .Select-value {
    margin-bottom: 5px;
}
