@font-face {
  font-family: 'icomoon';
  src:  url('fonts/new/icomoon.eot');
  src:  url('fonts/new/icomoon.eot#iefix') format('embedded-opentype'),
  url('fonts/new/icomoon.ttf') format('truetype'),
  url('fonts/new/icomoon.woff') format('woff'),
  url('fonts/new/icomoon.svg#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-close:before {
  content: "\ea0f";
}
.icon-star:before {
  content: "\e9d7";
}
.icon-star-full:before {
  content: "\e9d9";
}
.icon-profile-ava:before {
  content: "\e971";
}

.icon-print:before {
  content: "\e954";
}

.icon-eye:before {
  content: "\e9ce";
}

.icon-arrow-top:before {
  content: "\ea3b";
}

.icon-mail:before {
  content: "\ea86";
}